import React from 'react';
import uniqid from 'uniqid';
import { Link } from 'gatsby';
import { Trans } from '@lingui/macro';
import observability from './images/observability.svg';
import automation from './images/automation.svg';
import measurements from './images/measurements.svg';
import Carousel from '../Carousel';

const ReliabilityPillars = () => {
  const carouselSettings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    dotsClass: 'custom-dot-list',
    customPaging: (i) => (
      <button className="custom-dot">{`${carouselData[i].headerName}`}</button>
    ),
  };

  const carouselData = [
    {
      headerName: 'OBSERVABILITY',
      image: observability,
      text: (
        <>
        <p className="reliability-pillars__text">
          <Trans>
          System observability refers to the ability to monitor and measure the behavior of a software system,
          in near real-time, to gain insights into its performance, health, and overall operation.
          </Trans>
        </p>
        <p className="reliability-pillars__text">
          <Trans>
          System observability is a critical aspect of modern software systems, particularly in complex,
          distributed environments such as cloud-based applications or microservices architectures.
          With system observability, developers and operations teams can gain visibility into the
          behavior of the system at various levels, from the application layer down to the infrastructure layer,
          allowing them to identify issues quickly and efficiently.
          </Trans>
        </p>
        <p className="reliability-pillars__text">
          <Trans>
            We ensure your systems have everything required in order to allow our Reliability Engineers to have the
            right insights into the current status of your applications and the infrastructure at any
            point in time. This means introduction or improvement of:
          </Trans>
        </p>
        <p className="reliability-pillars__text">
          <ul>
            <li><Trans><strong>Metrics</strong>: quantitative data that measures the behavior of the system, such as response times, request rates, error rates, and resource utilization.</Trans></li>
            <li><Trans><strong>Logs</strong>: detailed event data that captures specific activities or errors that occur within the system.</Trans></li>
            <li><Trans><strong>Distributed tracing</strong>: data that captures the path of a particular transaction or request across multiple services, allowing developers to identify the root cause of issues and enabling end-to-end visibility of a distributed transaction or request.</Trans></li>
          </ul>
      </p>
      </>
      ),
    },
    {
      headerName: 'AUTOMATION',
      image: automation,
      text: (
        <>
            <p className="reliability-pillars__text">
              <Trans>
              Automating the resolution of expected erroneous situations is an <strong>essential step towards
              building self-healing systems</strong>. Self-healing systems are those that are capable of automatically
              detecting and resolving issues that arise without human intervention. This capability is crucial
              in modern software systems that require high levels of availability, performance, and reliability.
              </Trans>
            </p>
            <p className="reliability-pillars__text">
              <Trans>
              This may involve using machine learning algorithms to detect patterns in system behavior
              and automatically initiate remedial action, or building in failover and redundancy
              capabilities that can kick in automatically when issues are detected.
              </Trans>
            </p>
            <p className="reliability-pillars__text">
              <Trans>
              YanchWare's Cloud Reliability Center keeps track of recurring errors and automate resolution either
              through technical automation, self-service portals, and/or other solutions that will release the
              pressure on both operation and support for your resources.
              </Trans>
            </p>
        </>
      )
    },
    {
      headerName: 'MEASUREMENTS',
      image: measurements,
      text: (
        <>
          <p className="reliability-pillars__text">
            <Trans>
              We operate by defining quantifiable Key Performance Indicators (KPI) and setting up with the customer
              Objectives and Key Results in order to report continuously on progress and setting up an agile roadmap
              for constant improvement.
            </Trans>
          </p>
          <p className="reliability-pillars__text">
            <Trans>
              Some examples of KPIs we use are:
            </Trans>
          </p>
          <p className="reliability-pillars__text">
              <ul>
                <li><Trans><strong>Availability</strong>: Percentage of time that the services are available and accessible to end-users.</Trans></li>
                <li><Trans><strong>Mean time to resolution (MTTR)</strong>: This measures the time it takes for the Cloud Reliability Center to detect and resolve issues impacting the availability or performance of the cloud-based services.</Trans></li>
                <li><Trans><strong>Mean time between failures (MTBF)</strong>: This measures the average time between failures of the cloud-based services being managed.</Trans></li>
                <li><Trans><strong>Incident response time</strong>: This measures the time it takes for the Cloud Reliability Center to respond to and begin investigating incidents impacting the cloud-based services.</Trans></li>
                <li><Trans><strong>Change success rate</strong>: This measures the percentage of changes to the cloud-based services that are successfully implemented without causing downtime or other issues.</Trans></li>
                <li><Trans><strong>Scalability</strong>: This measures the ability of the cloud-based services to scale in order to meet changing demand.</Trans></li>
              </ul>
          </p>
        </>
        )
    },
  ];

  return (
    <div className="reliability-pillars-carousel-wrapper">
      <Carousel settings={carouselSettings}>
        {carouselData.map((slide) => (
          <div
            key={uniqid('reliability-pillars-')}
            className="reliability-pillars__slide"
          >
            <img
              src={slide.image}
              alt=""
              className="reliability-pillars__image"
            />
            <div className="reliability-pillars__slide-info">
              <p className="reliability-pillars__slide-text">{slide.text}</p>
              <Link
                to="/contact-us"
                className="reliability-pillars__button button--cta"
              >
                <Trans>Contact Us</Trans>
              </Link>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ReliabilityPillars;
