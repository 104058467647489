import 'scss/pages/services.scss';
import React, { useRef } from 'react';
import { Link } from 'gatsby';
import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Image from 'components/atoms/Image/Image';
import ReliabilityPillars from 'components/Carousel/ReliabilityPillars/ReliabilityPillars';
import Certifications from '../components/Carousel/Certifications/Certifications';

const Services = () => {
  const cloudReliability = useRef(null);
  const training = useRef(null);
  const consulting = useRef(null);
  const scrollTo = (element) => {
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Layout contactForm={false} className="service-page">
      <Seo title="Services" />
      <section className="hero-wrapper">
        <div className="hero">
          <div className="hero__content">
            <h1 className="hero__title">
              <Trans>Keep your edge</Trans>
            </h1>
            <p className="hero__text">
              <Trans>
                Develop your business and defend your position at{' '}
                <strong>the Forefront with YanchWare</strong>
              </Trans>
            </p>
          </div>
          <Image name="services-page/services-hero" className="hero__image" />
        </div>
      </section>

      <section className="bob">
        <h1 className="bob__title">
          <Trans>Boost your Business</Trans>
        </h1>

        <div className="boxes-container">
          <div className="box">
            <span className="box__number">
              <Trans>01</Trans>
            </span>
            <h2 className="box__title">
              <Trans>Deliver</Trans>
            </h2>
            <div className="box__text">
              <Trans>More ground-breaking features to your customers</Trans>
            </div>
          </div>
          <div className="box">
            <span className="box__number">
              <Trans>02</Trans>
            </span>
            <h2 className="box__title">
              <Trans>Differentiate</Trans>
            </h2>
            <div className="box__text">
              <Trans>From your competition</Trans>
            </div>
          </div>
          <div className="box">
            <span className="box__number">
              <Trans>03</Trans>
            </span>
            <h2 className="box__title">
              <Trans>Lead</Trans>
            </h2>
            <div className="box__text">
              <Trans>Your segment and your industry</Trans>
            </div>
          </div>
        </div>

        <h2 className="bob__focus-title">
          <Trans>
            You can do it all, but you need to{' '}
            <span className="bob__focus-title-accent">focus</span>.
          </Trans>
        </h2>
        <p>
          <Trans>
            Focus all your effort and resources on your <em>core domain</em>, that set of
            functionalities and services that distinguish you from the competition. At YanchWare we
            offer a complete package through our products and services ensuring you have a solid
            foundation to do just that, <strong>bring your business forward.</strong>
          </Trans>
        </p>
        <Link to="/contact-us" className="button--cta">
          <Trans>Contact us</Trans>
        </Link>
      </section>

      <section className="our-services">
        <div className="container">
          <h1 className="our-services__title">
            <Trans>Our Services</Trans>
          </h1>

          <div className="service-nav">
            <div className="service-nav-item">
              <h2 className="service-nav-item__title">
                <Trans>Cloud Reliability Center</Trans>
              </h2>
              <Image className="service-nav-item__image" name="cloud-reliability-center" />
              <p className="service-nav-item__text">
                <Trans>
                  24/7 First-line operational support for your cloud infrastructure. Focus on your
                  customers while we help in ensuring high-availability
                </Trans>
              </p>
              <button
                onClick={() => scrollTo(cloudReliability.current)}
                className="service-nav-item__button button--cta button--link button--arrow"
              >
                <Trans>Read More</Trans>
              </button>
            </div>
            <div className="service-nav-item">
              <h2 className="service-nav-item__title">
                <Trans>Training</Trans>
              </h2>
              <Image className="service-nav-item__image" name="training" />
              <p className="service-nav-item__text">
                <Trans>
                  Cloud-Native Transformation, Event Storming, Domain-Driven Design, Fractal
                  Architecture, and much more
                </Trans>
              </p>
              <button
                onClick={() => scrollTo(training.current)}
                className="service-nav-item__button button--cta button--link button--arrow"
              >
                <Trans>Read More</Trans>
              </button>
            </div>
            <div className="service-nav-item">
              <h2 className="service-nav-item__title">
                <Trans>Consulting</Trans>
              </h2>
              <Image className="service-nav-item__image" name="it-consulting" />
              <p className="service-nav-item__text">
                <Trans>
                  Increase your organization’s efficiency and responsiveness with our experienced
                  and passionate resources
                </Trans>
              </p>
              <button
                onClick={() => scrollTo(consulting.current)}
                className="service-nav-item__button button--cta button--link button--arrow"
              >
                <Trans>Read More</Trans>
              </button>
            </div>
          </div>
        </div>

        <div ref={cloudReliability} id="cloud-reliability" className="cloud-reliability">
          <h2 className="cloud-reliability__title">
            <Trans>Cloud Reliability Center</Trans>
          </h2>
          <div className="container cloud-reliability__content">
            <h3 className="cloud-reliability__sub-title">
              <Trans>
                Our specialized teams will be able to lift off your shoulders the weight
                of operational responsibility.
              </Trans>
            </h3>
            <p className="cloud-reliability__text">
              <Trans>
                Our Cloud Reliability Center (CRC) is a group responsible for <strong>ensuring the reliability, availability, and
                performance</strong> of cloud-based services and infrastructure. We typically focus on improving the quality
                of the services provided to customers or users, as well as identifying and resolving issues that may
                impact the reliability and availability of those services.
              </Trans>
            </p>
            <p className="cloud-reliability__text">
              <Trans>
                The CRC at YanchWare is staffed by <strong>experts in cloud computing</strong>, including cloud
                architects, engineers, and operations professionals. These experts have a deep understanding
                of cloud-based infrastructure, as well as the tools and processes necessary to manage and monitor
                cloud services effectively.
              </Trans>
            </p>
            <p className="cloud-reliability__text">
              <Trans>
                Our primary goal is to <strong>prevent downtime and outages in cloud services</strong>, as well as
                to minimize the impact of any issues that do occur. This can involve
                implementing best practices for managing cloud infrastructure, such as configuring failover
                and redundancy capabilities, as well as monitoring cloud-based services to identify potential
                issues before they impact users.
              </Trans>
            </p>
            <p className="cloud-reliability__text">
              <Trans>
                In addition to ensuring the reliability and availability of cloud services, the CRC can also take
                the responsibility for <strong>improving the performance of your services</strong>. This can involve
                identifying and resolving bottlenecks, optimizing resource utilization, and scaling services to
                meet changing demand.
              </Trans>
            </p>
            <p className="cloud-reliability__text">
              <Trans>
                Our Cloud Reliability Center can play a critical role in ensuring that your cloud-based services
                are available, reliable, and performant, and that they meet the needs of users and customers.
              </Trans>
            </p>

            <p className="cloud-reliability__text">
              <Trans>
                We operate through a framework optimized through years of practical experience in
                handling <strong>Enterprise Production Environments</strong>.
              </Trans>
            </p>
            <p className="cloud-reliability__text">
              <Trans>
                We do not limit our offering to the industry-standard 24/7 first-line support, but
                we enrich it further, ensuring that your systems and applications will continuously
                improve on quality and efficacy.
              </Trans>
            </p>
          </div>
          <div className="container reliability-pillars">
            <h3 className="reliability-pillars__title">
              <Trans>Our approach is based on 3 pillars.</Trans>
            </h3>
            <ReliabilityPillars />
          </div>
        </div>

        <div ref={training} id="training" className="training-service">
          <h2 className="training-service__title">
            <Trans>Training</Trans>
          </h2>
          <h3 className="training-service__sub-title">
            <Trans>Invest in your people with specialized and tailored technical training.</Trans>
          </h3>
          <p className="training-service__text">
            <Trans>
              We can cover all aspects of modern software development lifecycle, from Agile
              Transformation of your organization, through autonomous delivery teams, to actual
              development using state-of-the art software architecture and technology.
            </Trans>
          </p>
          <div className="training-service__highlight">
            <div className="training-service__vendors">
              <Image className="training-service__vendors-image" name="services-page/azure" />
              <Image className="training-service__vendors-image" name="services-page/gcp" />
              <Image className="training-service__vendors-image" name="services-page/aws" />
            </div>
            <p className="training-service__text">
              <Trans>
                We can share our knowledge and experience with your teams through practical hands-on
                training. We have extensive technical experience in using cloud services in an
                enterprise setting across <strong>MULTIPLE VENDORS</strong> and{' '}
                <strong>MULTIPLE MODELS</strong>.
              </Trans>
            </p>
          </div>
          <div className="learning content-blocks content-blocks--reverse content-blocks--image-background">
            <div className="content-block">
              <div className="content-block__info">
                <p className="content-block__text">
                  <Trans>
                    You can choose from our catalogue of ready-to-use courses or we can tailor the
                    course for your teams based on your <strong>strategy and goals</strong>.
                  </Trans>
                </p>
                <Link to="/contact-us" className="button--cta">
                  <Trans>Contact Us</Trans>
                </Link>
              </div>
              <Image name="services-page/courses" className="content-block__image" />
            </div>
          </div>
        </div>

        <div ref={consulting} id="consulting" className="consulting-service container">
          <h2 className="consulting-service__title">
            <Trans>Consulting</Trans>
          </h2>
          <p className="consulting-service__sub-title">
            <Trans>
              From core mainframe applications to complex distributed middleware platforms
            </Trans>
          </p>
          <h3 className="consulting-service__slogan">
            <Trans>WE HAVE DEALT WITH THEM ALL</Trans>
          </h3>
          <p className="consulting-service__text">
            <Trans>
              We can cover all aspects of modern software development lifecycle, from Agile
              Transformation of your organisation, through autonomous delivery teams, to actual
              development using state-of-the art software architecture and technology.
            </Trans>
          </p>

          <div className="content-blocks content-blocks--image-background">
            <div className="content-block">
              <div className="content-block__info">
                <p>
                  <Trans>
                    Our team of highly specialized technologists can help you in identifying and
                    reduce technical debt, while isolating, converting and reuse working legacy
                    systems.
                  </Trans>
                </p>
                <p>
                  <Trans>
                    This allows you to <strong>rapidly close the gap</strong> to cloud-native
                    architecture while continuously delivering business value.
                  </Trans>
                </p>
              </div>
              <Image name="services-page/gap" className="content-block__image" />
            </div>
          </div>

          <div className="consulting-contact">
            <h3 className="tac">
              <Trans>We have extensive experience</Trans>
            </h3>
            <p>
              <Trans>
                In designing, building and maintaining highly scalable and performant event-driven
                distributed systems. Some of our solution are used by millions of users, processing
                thousands of transactions per second.
              </Trans>
            </p>
            <Link to="/contact-us" className="button--cta">
              <Trans>Contact us</Trans>
            </Link>
          </div>
        </div>

        <div id="certifications" className="certifications container">
          <h2 className="certifications__title">
            <Trans>Certifications</Trans>
          </h2>
          <Certifications />
        </div>
      </section>
    </Layout>
  );
};

export default Services;
