import './Certifications.scss';
import React from 'react';
import {Trans} from '@lingui/macro';
import uniqid from 'uniqid';
import iso9001 from './images/iso9001.svg';
import Carousel from '../Carousel';

const Certifications=() => {
  const carouselSettings={
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    dotsClass: 'custom-dot-list',
    customPaging: ( i ) => <button className="custom-dot">{`${carouselData[ i ].headerName}`}</button>,
  };

  const carouselData=[
    {
      headerName: 'ISO 9001',
      image: iso9001,
      text: (
        <div>
          <p>
            <Trans>
              ISO 9001 certification is a worldwide recognition of YanchWare's consistent and
              superior quality in customer service, as well as an assurance of high-quality products
              that include memory enhancements and data storage products.
            </Trans>
          </p>
          <p>
            <Trans>
              <strong>YanchWare</strong> undergoes regular audits by third party ISO assessors,
              customers, suppliers and its own employees. The results of these audits are used to
              improve and strengthen our quality management system.
            </Trans>
          </p>
          <p>
            <Trans>
              We share the benefits of our ISO 9001 certification with our customers and take pride
              in our commitment to complete customer satisfaction.{' '}
            </Trans>
          </p>
        </div>
      ),
    },
    {
      headerName: 'ISO 27001',
      image: '',
      text: (
        <div className="certifications__coming-soon">
          <h3 className="certifications__coming-soon-inner">
            <Trans>Coming soon</Trans>
          </h3>
        </div>
      ),
    },
  ];

  return (
    <div className="certifications-carousel-wrapper">
      <Carousel settings={carouselSettings}>
        {carouselData.map( ( slide ) => (
          <div key={uniqid( 'certifications-' )} className="certifications__slide">
            <div className="certifications__slide-text">{slide.text}</div>
            {slide.image&&<img src={slide.image} alt="" className="certifications__image" />}
          </div>
        ) )}
      </Carousel>
    </div>
  );
};

export default Certifications;
